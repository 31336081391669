<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.module.adobeConnect.menu.setting') }}
        </h2>

        <router-link to="/module/adobe-connect" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.module.adobeConnect.title") }}
        </router-link>
    </div>
    <div class="card" v-if="tabSkeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton/>
        </div>
    </div>
    <el-form :model="form.data" ref="settingForm" v-else>
        <div class="card">
            <div class="card-body d-flex flex-column p-9">
                <div class="row fv-row mb-7 align-items-center" v-for="(item, itemIndex) in settings" :key="itemIndex">
                    <div class="col-md-3 mb-2 mb-md-0">
                        <label class="fs-6 fw-bold">{{ item.title }}</label>
                    </div>
                    <div class="col-md-9">
                        <el-form-item :prop="item.opt" class="mb-0" v-init="form.data[item.opt] = item.data">
                            <el-input v-if="item.type === 'input'" v-model="form.data[item.opt]"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="fv-row mt-5">
                    <el-form-item class="mb-0 text-end">
                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </el-form-item>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            tabSkeleton: true,
            form: {
                loading: false,
                data: {}
            },
            settings: {}
        }
    },
    created() {
        this.loadSettings();
    },
    methods: {
        loadSettings() {
            this.tabSkeleton = true;

            this.axios.get(this.endpoints['module_adobe_connect_setting']).then((response) => {
                let data = response.data.data;
                this.settings = data;
            }).finally(() => {
                this.tabSkeleton = false;
            });
        },
        onSubmit() {
            this.$refs.settingForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    this.axios.post(this.endpoints['module_adobe_connect_setting'], formData).then(response => {
                        this.loadSettings();
                        this.onResponse(response.data);
                    }).catch(error => {
                        this.onResponseFailure(error.response.data)
                    }).finally(() => {
                        this.form.loading = false;
                    });

                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = {};
            formData.credentials = this.cloneData(this.form.data);

            return formData
        }
    }
}
</script>

<style scoped>

</style>